import { graphql } from "gatsby";
import React from "react";
import AnnotationTypes from "../components/AnnotationTypes";
import BetterModels from "../components/annotation_Pages/BetterModels";
import HeroSection from "../components/annotation_Pages/HeroSection";
import SupportingAI from "../components/annotation_Pages/SupportingAI";
import SideCard from "../components/home_Pages/SideCard";
import Layout from "../components/layout";
import RowsSection from "../components/sar_Pages/RowsSection";
import SEO from "../components/seo";
import "../styles/page/annotation_pages.scss";

const ImageAnnotation = ({ data }) => {
  const _data = data?.prismicNewImageAnnotationPage?.data || {};

  const {
    trusted_brands_list = [[]],
    annotation_types = [[]],
    enhanced_qa = [[]],
    encord_accelerate = [[]],
    format_support = [[]],
    quality_metrics = [[]],
    worflows = [[]],
    data_management = [[]],
    in_depth_ontologies = [[]],
    micro_models = [[]],
    foundational_models = [[]],
    supporting_ai_teams = [[]],
    better_model = [[]],
    g2_rating = [[]],
  } = _data.body?.reduce((acc, v) => {
    acc[v.slice_type] = v;
    return acc;
  }, {});

  return (
    <Layout mainClasses="">
      <article className="relative px-5 pt-32 mx-auto text-center max-w-7xl md:pt-44">
        <HeroSection
          trusted_brands_list={trusted_brands_list}
          imageSRC={_data?.hero_image?.url}
          imageWidth={_data?.hero_image?.dimensions?.width}
          imageHeight={_data?.hero_image?.dimensions?.height}
          videoSRC={_data?.main_hero_video?.url}
          heading={_data?.page_heading?.html}
          description={_data?.page_description?.text}
          pageName="new image"
        />

        <AnnotationTypes annotation_types={annotation_types} />

        <section className="flex flex-col lg:flex-row gap-x-6">
          <SideCard
            data={worflows?.primary}
            imageClasses="mx-5"
            containerClasses={"!h-auto"}
          />

          <SideCard
            data={in_depth_ontologies?.primary}
            imageClasses="mx-5"
            containerClasses={"!h-auto"}
          />
        </section>

        <section className="full-bleed-gray mt-9 mb-14">
          {micro_models?.primary && (
            <RowsSection
              isReverse={false}
              btnLink={micro_models?.primary?.learn_more_link?.url}
              btnText="Learn more"
              description={micro_models?.primary?.section_description?.text}
              heading={micro_models?.primary?.section_heading?.text}
              imgAlt={micro_models?.primary?.section_image?.url || ""}
              imgSrc={micro_models?.primary?.section_image?.url}
              imageWidth={
                micro_models?.primary?.section_image?.dimensions?.width
              }
              imageHeight={
                micro_models?.primary?.section_image?.dimensions?.height
              }
              videoSrc={micro_models?.primary?.section_video?.url}
              subHeading={micro_models?.primary?.section_label?.text}
            />
          )}
        </section>

        <section className="grid grid-cols-6 gap-6">
          <section className="col-span-6 lg:col-span-2 card_spacing">
            <SideCard
              data={data_management?.primary}
              imageClasses="h-[300px] mx-auto object-contain"
              direction="reverse"
              containerClasses="!justify-end m-0"
            />
          </section>
          <section className="col-span-6 lg:col-span-4">
            <SideCard
              data={enhanced_qa?.primary}
              imageClasses="h-[300px] mx-auto object-contain"
              direction="reverse"
              containerClasses="!justify-end m-0"
            />
          </section>
        </section>

        <section className="grid grid-cols-6 gap-6 mt-16">
          <section className="col-span-6 lg:col-span-4 image_card_link_spacing">
            <SideCard
              data={format_support?.primary}
              imageClasses="h-[300px] mx-auto object-contain"
              direction="reverse"
              containerClasses="!justify-end m-0"
            />
          </section>

          <section className="col-span-6 lg:col-span-2">
            <SideCard
              data={quality_metrics?.primary}
              imageClasses="h-[300px] mx-auto object-contain"
              direction="reverse"
              containerClasses="!justify-end m-0"
            />
          </section>
        </section>

        <section className="mt-10 space-y-12">
          {foundational_models?.primary && (
            <RowsSection
              isReverse={false}
              btnLink={foundational_models?.primary?.learn_more_link?.url}
              btnText="Learn more"
              description={
                foundational_models?.primary?.section_description?.text
              }
              heading={foundational_models?.primary?.section_heading?.text}
              imgAlt={foundational_models?.primary?.section_image?.url || ""}
              imgSrc={foundational_models?.primary?.section_image?.url}
              imageWidth={
                foundational_models?.primary?.section_image?.dimensions?.width
              }
              imageHeight={
                foundational_models?.primary?.section_image?.dimensions?.height
              }
              videoSrc={foundational_models?.primary?.section_video?.url}
              subHeading={foundational_models?.primary?.section_label?.text}
            />
          )}
          {encord_accelerate?.primary && (
            <RowsSection
              isReverse={true}
              btnLink={encord_accelerate?.primary?.learn_more_link?.url}
              btnText="Learn more"
              description={
                encord_accelerate?.primary?.section_description?.text
              }
              heading={encord_accelerate?.primary?.section_heading?.text}
              imgAlt={encord_accelerate?.primary?.section_image?.url || ""}
              imgSrc={encord_accelerate?.primary?.section_image?.url}
              imageWidth={
                encord_accelerate?.primary?.section_image?.dimensions?.width
              }
              imageHeight={
                encord_accelerate?.primary?.section_image?.dimensions?.height
              }
              subHeading={encord_accelerate?.primary?.section_label?.text}
              imageContainer="shadow-none h-[470px]"
            />
          )}
        </section>

        <SupportingAI
          data={supporting_ai_teams?.primary}
          items={supporting_ai_teams?.items}
        />
      </article>

      <BetterModels
        heading={better_model?.primary?.section_heading?.text}
        description={better_model?.primary?.section_description?.text}
        ctaHeading={better_model?.primary?.cta_heading?.text}
        ctaDescription={better_model?.primary?.cta_description?.text}
        modelTypes={better_model?.items}
        g2RatingData={g2_rating?.items}
        pageName="new image"
      />
    </Layout>
  );
};

export default ImageAnnotation;

export const query = graphql`
  query {
    prismicNewImageAnnotationPage {
      data {
        body {
          ... on PrismicNewImageAnnotationPageDataBodyAnnotationTypes {
            id
            slice_type
            primary {
              section_description {
                html
                text
              }
              section_heading {
                html
                text
              }
              section_label {
                html
                text
              }
            }
            items {
              label {
                html
                text
              }
              label_icon {
                alt
                url
              }
              label_slide_image {
                alt
                url
              }
              label_slide_video {
                url
              }
            }
          }
          ... on PrismicNewImageAnnotationPageDataBodyBetterModel {
            id
            items {
              section_feature_description {
                html
                text
              }
              section_feature_heading {
                html
                text
              }
              section_feature_icon {
                alt
                url
              }
            }
            primary {
              cta_description {
                html
                text
              }
              cta_heading {
                html
                text
              }
              section_description {
                html
                text
              }
              section_heading {
                html
                text
              }
            }
            slice_type
          }
          ... on PrismicNewImageAnnotationPageDataBodyEncordAccelerate {
            id
            slice_type
            primary {
              learn_more_link {
                url
              }
              section_description {
                html
                text
              }
              section_heading {
                html
                text
              }
              section_image {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
              section_label {
                html
                text
              }
            }
          }
          ... on PrismicNewImageAnnotationPageDataBodyDataManagement {
            id
            slice_type
            primary {
              learn_more_link {
                url
              }
              section_description {
                html
                text
              }
              section_heading {
                html
                text
              }
              section_image {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
              section_label {
                html
                text
              }
            }
          }
          ... on PrismicNewImageAnnotationPageDataBodyEnhancedQa {
            id
            slice_type
            primary {
              learn_more_link {
                url
              }
              section_description {
                html
                text
              }
              section_heading {
                html
                text
              }
              section_image {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
              section_label {
                html
                text
              }
            }
          }
          ... on PrismicNewImageAnnotationPageDataBodyFormatSupport {
            id
            slice_type
            primary {
              learn_more_link {
                url
              }
              section_description {
                html
                text
              }
              section_heading {
                html
                text
              }
              section_image {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
              section_video {
                url
              }
              section_label {
                html
                text
              }
            }
          }
          ... on PrismicNewImageAnnotationPageDataBodyFoundationalModels {
            id
            slice_type
            primary {
              learn_more_link {
                url
              }
              section_description {
                html
                text
              }
              section_heading {
                html
                text
              }
              section_image {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
              section_video {
                url
              }
              section_label {
                html
                text
              }
            }
          }
          ... on PrismicNewImageAnnotationPageDataBodyG2Rating {
            id
            slice_type
            items {
              section_description {
                html
                text
              }
              section_heading {
                html
                text
              }
            }
          }
          ... on PrismicNewImageAnnotationPageDataBodyInDepthOntologies {
            id
            slice_type
            primary {
              learn_more_link {
                url
              }
              section_description {
                html
                text
              }
              section_label {
                html
                text
              }
              section_image {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
              section_heading {
                html
                text
              }
            }
          }
          ... on PrismicNewImageAnnotationPageDataBodyMicroModels {
            id
            slice_type
            primary {
              learn_more_link {
                url
              }
              section_description {
                html
                text
              }
              section_heading {
                html
                text
              }
              section_image {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
              section_video {
                url
              }
              section_label {
                html
                text
              }
            }
          }
          ... on PrismicNewImageAnnotationPageDataBodyQualityMetrics {
            id
            slice_type
            primary {
              learn_more_link {
                url
              }
              section_description {
                html
                text
              }
              section_heading {
                html
                text
              }
              section_image {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
              section_label {
                html
                text
              }
            }
          }
          ... on PrismicNewImageAnnotationPageDataBodySupportingAiTeams {
            id
            slice_type
            items {
              card_description {
                html
                text
              }
              card_image {
                alt
                url
              }
              card_label {
                html
                text
              }
            }
            primary {
              section_description {
                html
                text
              }
              section_heading {
                html
                text
              }
            }
          }
          ... on PrismicNewImageAnnotationPageDataBodyTrustedBrandsList {
            id
            primary {
              section_heading {
                html
                text
              }
            }
            items {
              brand_logo {
                url
                alt
                dimensions {
                  height
                  width
                }
              }
            }
            slice_type
          }
          ... on PrismicNewImageAnnotationPageDataBodyWorflows {
            id
            slice_type
            primary {
              learn_more_link {
                url
              }
              section_description {
                html
                text
              }
              section_heading {
                html
                text
              }
              section_image {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
              section_label {
                html
                text
              }
            }
          }
        }
        hero_image {
          alt
          url
          dimensions {
            height
            width
          }
        }
        main_hero_video {
          url
        }
        meta_description {
          html
          text
        }
        meta_heading {
          html
          text
        }
        page_description {
          html
          text
        }
        page_heading {
          html
          text
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
  const _data = data?.prismicNewImageAnnotationPage?.data || {};
  return (
    <SEO
      title={_data?.meta_heading?.text}
      description={_data?.meta_description?.text}
    >
      <meta name="robots" content="noindex, nofollow" />
    </SEO>
  );
};
