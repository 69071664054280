import React from "react";

const SupportingAI = ({
  data,
  items,
  isCustomBg = false,
  isCustomTitleColor = false,
  customBgColor,
  customTitleColor,
  isCustomCard = false,
  isCustomCardData,
  isCustomCardItems,
  cardImageClass = "",
}) => {
  const { section_heading, section_description } = data;
  const { section_heading: custom_section_heading } = isCustomCardData || {};
  return (
    <section
      className={`p-5 ${
        isCustomBg ? customBgColor : "full-bleed-gray"
      } 2xl:p-10 md:p-8`}
    >
      <section className="max-w-2xl text-left">
        <h4
          className={`${
            isCustomTitleColor ? customTitleColor : "text-blue-1000"
          } text-2xl sm:text-3.5xl font-semibold pt-5`}
        >
          {section_heading?.text}
        </h4>
        <p className="py-5 text-sm text-gray-600 sm:text-base ">
          {section_description?.text}
        </p>
      </section>

      <section className={`flex flex-wrap justify-center gap-6  ${isCustomBg?"xl:flex-nowrap":"lg:flex-nowrap"}`}>
        {items?.map(({ card_description, card_image, card_label }) => {
          return (
            <section
              className={`p-7 ${
                isCustomBg ? "industry_card" : "ai_cart"
              } text-left lg:flex-1 md:flex-[0.5] lg:min-w-[380px] min-w-full`}
            >
              <h4 className="text-xl font-semibold text-purple-1000">
                {card_label?.text}
              </h4>
              <img
                src={card_image?.url}
                alt={card_image?.alt || "logo"}
                width={card_image?.dimensions?.width || 0}
                height={card_image?.dimensions?.height || 0}
                className={`py-5 ${
                  card_image?.dimensions?.width >= 70 &&
                  card_image?.dimensions?.height <= 20
                    ? "sm:w-44 w-40 h-20":card_image?.dimensions?.width == 624 &&
                    card_image?.dimensions?.height == 47?"mt-4 w-80"
                    : cardImageClass
                }`}
              />
              <p className="py-5 text-sm text-gray-600 sm:text-base ">
                {card_description?.text}
              </p>
            </section>
          );
        })}

        {isCustomCard && (
          <section
            className={`p-7 ${
              isCustomBg ? "industry_card" : "ai_cart"
            } text-left lg:flex-1 md:flex-[0.5] lg:min-w-[380px] min-w-full`}
          >
            <h4 className="text-xl font-semibold text-purple-1000 mb-5">
              {custom_section_heading?.text}
            </h4>

            {isCustomCardItems?.map(
              ({ custom_card_icon, card_description }) => {
                return (
                  <div className="p-4 rounded-xl border border-gray-1400 flex items-center sm:mb-1 mb-5">
                    <img
                      src={custom_card_icon?.url}
                      alt={"logo"}
                      className=""
                    />
                    <p className="text-sm text-gray-600 sm:text-base ml-2.5">
                      {card_description?.text}
                    </p>
                  </div>
                );
              }
            )}
          </section>
        )}
      </section>
    </section>
  );
};

export default SupportingAI;
